<template>
    <section>
        <div class="page-header">
            <h3 class="page-title" data-aos="fade-left"
            data-aos-anchor="#example-anchor"
            data-aos-offset="500"
            data-aos-duration="2000" >
                <span class="page-title-icon bg-gradient-primary text-white mr-2">
                <i class="mdi mdi-home"></i>
                </span> {{site}}
            </h3>
            <nav aria-label="breadcrumb">
                <ul class="breadcrumb">
                <li class="breadcrumb-item active" aria-current="page">
                    <span></span>site <i class="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
                </li>
                </ul>
            </nav>
        </div>
        
        <div class="row">
            <div class="col-md-4 stretch-card grid-margin" v-for="service in api_services" :key="service.id" @click="goTo(service.link)">
                <div class="card card-img-holder text-white" :class="service.color">
                    <div class="card-body pointer">
                    <img src="@/assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
                    <h4 class="font-weight-normal mb-3"><i class="mdi-24px float-right" :class="service.icon"></i>
                    </h4>
                    <h2 class="mb-5">{{service.title}}</h2>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name:"api-services-list",
    data(){
        return{
            ulid:"",
            site:"",
            api_services:[
                { title: "Site Web", link:"pages", color:"bg-gradient-darkgreen", icon:"mdi mdi-web"},
                { title: "Blogs et articles", link:"posts", color:"bg-gradient-blue1", icon:"mdi mdi-comment"},
                { title: "Antivirus", color:"bg-gradient-lightgreen", icon:"mdi mdi-lock"},
                { title: "Hébergement", color:"bg-gradient-darkgreen", icon:"mdi mdi-web"}
            ]
        }
    },
    methods:{
        goTo(name){
            this.$router.push({
                name:name,
                params:{
                    ulidsite:this.ulid,
                    site: this.site
                }
            })
        }
    },
    created(){
        this.ulid = this.$route.params.ulidsite,
        this.site = this.$route.params.site
    }
}
</script>
<style scoped>
.strech-card{
  transition: all .3s ease-out !important;
}
.stretch-card:hover{
    transform: translate(0, -5px);
}

</style>